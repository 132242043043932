import * as React from "react";
import { graphql } from "gatsby"

import BrewlogArticle from '../components/BrewlogArticle';

export default function Brewlog({ data }) {
  const doc = data.mdx;
  return (
    <BrewlogArticle doc={doc} />
  );
}

export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      ...brewlogData
    }
  }
`;